import React from 'react'
import "../JobsPage/JobsPage.css"



const JobsPage = () => {
  return (
    <div><h1 style={{textAlign:"center", color:"red"}}>Under Construction !! </h1>  </div>
  )
}

export default JobsPage