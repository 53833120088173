
import React from 'react'
import "../LoanPage/LoanPage.css"


const LoanPage = () => {
  return (
    <div><h1 style={{textAlign:"center", color: "red"}}> Under Construction !!</h1></div>
  )
}

export default LoanPage