
import './App.css';
import Body from './Components/Body/Body';


function App() {
  return (
    <div className="App">
  <Body/>

  
    </div>
  );
}

export default App;
