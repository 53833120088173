import "../Navbar/Navbar.css"
import { Link } from "react-router-dom"
const Navbar = () => {
  return (
    <div className="mainContainer">

      <div className="subContainer_navbar">
        <div className="leftSide">
          <img src="Logo/logo.jpg" alt="logo" className="logoImage" />
          </div>
        <div className="rightside">
          <Link to="/" className="textRightSide">Home</Link>
          <Link to="/search" className="textRightSide">Search</Link>
          <Link to="/jobs" className="textRightSide">Jobs</Link>
          <Link to="/loan" className="textRightSide">Loan</Link>
          </div>
      </div>
        

    </div>
  )
}

export default Navbar