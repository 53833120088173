import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import SearchPage from './Components/SearchPage/SearchPage';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import LoanPage from './Components/LoanPage/LoanPage';
import JobsPage from "./Components/JobsPage/JobsPage"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <BrowserRouter>
    <Navbar/>
    <Routes>
      <Route path="/"  element={<App/>}/>
      <Route path="/search"  element={<SearchPage/>}/>
      <Route path="/loan"  element= {<LoanPage/>}  />
      <Route path="/jobs"  element= {<JobsPage/>}  />
    </Routes>
    <Footer/>
    </BrowserRouter>
  
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
