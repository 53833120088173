import React, { useState, useContext, createContext } from 'react';
import clientData from '../data.json';
import "../SearchPage/SearchPage.css"

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [data, setData] = useState(clientData);
  return (
    <DataContext.Provider value={{ data }}>
      {children}
    </DataContext.Provider>
  );
};

const SearchForm = () => {
  const { data } = useContext(DataContext);
  const [searchId, setSearchId] = useState('');
  const [client, setClient] = useState(null);
  const [error, setError] = useState('');

  const handleSearch = () => {
    if (!searchId) {
      setError('Please enter a client ID');
      setClient(null);
      return;
    }

    const foundClient = data.find(client => client.id === searchId);

    if (foundClient) {
      setClient(foundClient);
      setError('');
    } else {
      setError('Client not found');
      setClient(null);
    }
  };

  const handleInputChange = (e) => {
    setSearchId(e.target.value);
    if (!e.target.value) {
      setClient(null);
      setError('');
    }
  };

  return (
    <div>
      <form className='headContainer_form_searchpage'>
        <div className="form-group ">
          <label htmlFor="formClientId" className='clientIDLable'>Client ID</label>
          <input
            type="text"
            id="formClientId"
            className="form-control inputBox_searchpage"
            placeholder="Enter client ID"
            value={searchId}
            onChange={handleInputChange}
          />
        </div>
        <div>
        <button type="button" className="button_SearchPage " onClick={handleSearch}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-search searchIcon" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg>
        </button>
        </div>
      </form>
      {error && <div className="alert">{error}</div>}
      {client && (
        <div className='details_searchpage_aftername'>
          <div className='subContainer_details_searchpage_aftername'>
          <p><strong>Name:</strong> {client.name}</p>
          <p><strong>Mobile:</strong>+91-{client.mobile}</p>
          </div>
          <h5>Transaction Details</h5>
          <hr style={{width:"20%"}} className='hrLine' />
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Payment Received</th>
              </tr>
            </thead>
            <tbody>
              {client.transactionDetails.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.date}</td>
                  <td><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
</svg>{transaction.amount}</td>
                  <td>{transaction.paymentReceived ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const SearchPage = () => {
  return (
    <DataProvider>
      <div className="maincontainer_searchpage">
        <h5>Client Transactions Detail Search</h5>
        <SearchForm />
      </div>
    </DataProvider>
  );
};

export default SearchPage;
