import React from 'react'
import "../Footer/Footer.css"
const Footer = () => {
  return (
    <>
    

    <div className='MainCOntainer_footer'>
    <p>support@dgnmoney.com</p>
  <p>Copyright © 2024-25 "DGN Money" all rights reserved.</p>
    </div>
    </>
  )
}

export default Footer